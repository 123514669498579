<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols='12'>
          <v-card-title >
            2. Первая помощь при ожогах
          </v-card-title>
        </v-col>
        <v-col
          cols="6"
          class="pb-0"
        >
            <v-alert
              border="left"
              colored-border
              color="red"
              class="title font-weight-light pl-10"
              elevation="2"
            >
            <p><strong>Ожог </strong> - повреждение тканей организма, вызванное действием высокой температуры или химических веществ (щелочей, кислот, солей тяжелых металлов и другие).</p>
            <p>Чем больше площадь и глубже повреждения тканей, тем тяжелее течение ожога.</p>
          </v-alert>
        </v-col>
        <v-col cols="6">
          <v-card class="d-flex">
            <v-img
              lazy-src="img/material/flask.svg"
              width="15%"
              contain
              src="img/material/flask.svg"
              class="ma-5"
            ></v-img>
            <div>
              <v-card-title>
                При химическом ожоге:
              </v-card-title>
              <v-card-text class="title font-weight-light mb-0 pb-0">
                <v-icon color="red">mdi-check-all</v-icon>
                кислотой промыть пораженное место холодной водой в течение 20 минут;
              </v-card-text>
              <v-card-text class="title font-weight-light mb-4 pb-0">
                <v-icon color="red">mdi-check-all</v-icon>
                щелочью обработать 5-10% раствором борной или 2% растровом уксусной или лимонной кислоты.
              </v-card-text>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card-title>
            Степени ожога:
          </v-card-title>
          <v-tabs v-model="tab" color="red"  grow>
            <v-tab>I степень</v-tab>
            <v-tab>II степень</v-tab>
            <v-tab>III-IV степень</v-tab>
          </v-tabs>
        </v-col>
        <v-col cols="12">
          <v-tabs-items vertical v-model="tab">
            <v-tab-item>
              <v-row>
                <v-col cols="4">
                  <v-card-title>
                    Действия:
                  </v-card-title>
                  <v-card-text class="title font-weight-light mb-0 pb-0">
                    <v-icon class="mr-2" color="red">mdi-check-all</v-icon>
                    Охлаждение под проточной холодной водой или льдом в течение 20 минут;
                  </v-card-text>
                  <v-card-text class="title font-weight-light mb-0 pb-0">
                    <v-icon class="mr-2" color="red">mdi-check-all</v-icon>
                    Нетугая стерильная повязка;
                  </v-card-text>
                  <v-card-text class="title font-weight-light mb-0 pb-0">
                    <v-icon class="mr-2" color="red">mdi-check-all</v-icon>
                    Теплое питье.
                  </v-card-text>
                </v-col>
                <v-col cols="4">
                  <v-card-title>
                    Запрещено:
                  </v-card-title>
                  <v-card-text class="title font-weight-light mb-0 pb-0">
                    <v-icon class="mr-2" color="red">mdi-alert-outline</v-icon>
                    Покрывать мазью, маслом, они создают барьер для охлаждения;
                  </v-card-text>
                  <v-card-text class="title font-weight-light mb-0 pb-0">
                    <v-icon class="mr-2" color="red">mdi-alert-outline</v-icon>
                    Обрабатывать спиртом;
                  </v-card-text>
                  <v-card-text class="title font-weight-light mb-0 pb-0">
                    <v-icon class="mr-2" color="red">mdi-alert-outline</v-icon>
                    Накладывать давящую повязку.
                  </v-card-text>
                </v-col>
                <v-col cols="4">
                  <v-tooltip bottom color="red">
                    <template v-slot:activator="{ on }">
                      <v-img
                        lazy-src="img/material/hand-01.png"
                        width="80%"
                        src="img/material/hand-01.png"
                        class="ma-5 ptm-img-cp"
                        v-on="on"
                      ></v-img>
                    </template>
                    <span class="pa-5 subtitle-1 ">Покраснение кожи</span>
                  </v-tooltip>

                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-row>
                <v-col cols="4">
                  <v-card-title>
                    Действия:
                  </v-card-title>
                  <v-card-text class="title font-weight-light mb-0 pb-0">
                    <v-icon class="mr-2" color="red">mdi-check-all</v-icon>
                    Нетугая стерильная повязка;
                  </v-card-text>
                  <v-card-text class="title font-weight-light mb-0 pb-0">
                    <v-icon class="mr-2" color="red">mdi-check-all</v-icon>
                    Теплое питье;
                  </v-card-text>
                  <v-card-text class="title font-weight-light mb-0 pb-0">
                    <v-icon class="mr-2" color="red">mdi-check-all</v-icon>
                    Теплое питье.
                  </v-card-text>
                </v-col>
                <v-col cols="4">
                  <v-card-title>
                    Запрещено:
                  </v-card-title>
                  <v-card-text class="title font-weight-light mb-0 pb-0">
                    <v-icon class="mr-2" color="red">mdi-alert-outline</v-icon>
                    Покрывать мазью, маслом, они создают барьер для охлаждения;
                  </v-card-text>
                  <v-card-text class="title font-weight-light mb-0 pb-0">
                    <v-icon class="mr-2" color="red">mdi-alert-outline</v-icon>
                    Накладывать давящую повязку;
                  </v-card-text>
                  <v-card-text class="title font-weight-light mb-0 pb-0">
                    <v-icon class="mr-2" color="red">mdi-alert-outline</v-icon>
                    Прокладывать, смачивать пузыри.
                  </v-card-text>
                </v-col>
                <v-col cols="4">

                  <v-tooltip bottom color="red">
                    <template v-slot:activator="{ on }">
                      <v-img
                        lazy-src="img/material/hand-02.png"
                        width="80%"
                        src="img/material/hand-02.png"
                        class="ma-5 ptm-img-cp "
                        v-on="on"
                      ></v-img>
                    </template>
                    <span class="pa-5 subtitle-1 ">Покраснение кожи, отеки, боль, водянистые пузыри</span>
                  </v-tooltip>

                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-row>
                <v-col cols="3">
                  <v-card-title>
                    Действия:
                  </v-card-title>
                  <v-card-text class="title font-weight-light mb-0 pb-0">
                    <v-icon class="mr-2" color="red">mdi-check-all</v-icon>
                    Нетугая стерильная повязка;
                  </v-card-text>
                  <v-card-text class="title font-weight-light mb-0 pb-0">
                    <v-icon class="mr-2" color="red">mdi-check-all</v-icon>
                    Теплое питье.
                  </v-card-text>
                </v-col>
                <v-col cols="5">
                  <v-card-title>
                    Запрещено:
                  </v-card-title>
                  <v-card-text class="title font-weight-light mb-0 pb-0">
                    <v-icon class="mr-2" color="red">mdi-alert-outline</v-icon>
                    Покрывать мазью, маслом, они создают барьер для охлаждения;
                  </v-card-text>
                  <v-card-text class="title font-weight-light mb-0 pb-0">
                    <v-icon class="mr-2" color="red">mdi-alert-outline</v-icon>
                    Обрабатывать спиртом;
                  </v-card-text>
                  <v-card-text class="title font-weight-light mb-0 pb-0">
                    <v-icon class="mr-2" color="red">mdi-alert-outline</v-icon>
                    Накладывать давящую повязку;
                  </v-card-text>
                  <v-card-text class="title font-weight-light mb-0 pb-0">
                    <v-icon class="mr-2" color="red">mdi-alert-outline</v-icon>
                    Поливать водой, прикладывать лед;
                  </v-card-text>
                  <v-card-text class="title font-weight-light mb-0 pb-0">
                    <v-icon class="mr-2" color="red">mdi-alert-outline</v-icon>
                    Срывать прилипшую одежду.
                  </v-card-text>
                </v-col>
                <v-col cols="4">

                  <v-tooltip bottom color="red">
                    <template v-slot:activator="{ on }">
                      <v-img
                        lazy-src="img/material/hand-03.png"
                        width="80%"
                        src="img/material/hand-03.png"
                        class="ma-5 ptm-img-cp "
                        v-on="on"
                      ></v-img>
                    </template>
                    <span class="pa-5 subtitle-1 ">Обугливание, корочки на поверхности кожи</span>
                  </v-tooltip>

                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
      <Hint></Hint>
    </v-container>
  </div>
</template>
<script>
import Hint from '@/components/Hint.vue'
export default {
  data: () => ({
    tab: null
  }),
  components: {
    Hint
  }
}
</script>
<style lang="less">
.ptm-img-cp {
  cursor: pointer;
}
</style>
